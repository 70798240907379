.header{
    height: 550px;
    width: 100%;
    background: url('/images/sale.png') no-repeat;
    border-top: 1px solid #231F1C;
}

.wrapper-header{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 0;
    padding: 0;
}

.header-h1{ 
    width: 725px;
    font-style: normal;
    font-family: 'Lato';
    font-weight: 800;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    position: relative;
    top: 120px;
}

.header-h2{
    width: 405px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.03em;
    color: #FFFFFF;
    position: relative;
    top: 120px;
}

.header-a{
    display: block;
    width: 220px;
    height: 39px;
    background: #FFC82E;
    border: none;
    outline: none;
    position: relative;
    top: 160px;
    text-decoration: none;
    color: #231F1C;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    padding-top: 20px;
    text-align: center;
}

.header-a:hover{
    background: #FFD55F;
}

.header-a:active{
    background: #FFB82E;
}

.sale{
    width: 505px;
    height: 251px;
    position: absolute;
    top: 272.5px;
    right: 0;
}

.sale-img{
    height: 160px;
    margin-top: 35px;
}

.sale-info{
    width: 470px;
    height: 210px;
    margin-top: -109.5px;
    margin-left: 35px;
    background: #231F1C;
    display: flex;
    justify-content: center;
}
.sale-sale{
    width: 300px;
    margin-left: -40px;
}
.sale-title{
    width: 100%;
    margin: 0 auto;
    margin-left: 50px;
    padding-top: 48px;
    font-weight: 400;
    font-size: 26px;
    line-height: 140%;
    color: #FFC82E;
}

.sale-description{
    width: 90%;
    margin-left: 49px;
    margin-top: 15px;
    font-weight: 300;
    letter-spacing: 0.6px;
    font-size: 12px;
    line-height: 140%;
    color: #FFFFFF;
}

@media (min-width: 1280px) {
    .header {
        background-size: 100%;
    }
}

@media (max-width: 1110px) {
    .sale {
        bottom: 0;
    }
}


@media (min-width: 620px) and (max-width: 820px){
    .header-h1 {
        width: 430px;
        font-size: 35px;
        top: 10vw;
    } 
    .header-h2 {
        width: 90%;
        font-size: 3vw;
        top: 10vw;
    } 
    .header-a {
        top: 17vw;
        width: 100%;
    }
    .sale {
        display: none;
    }
    .header {
        height: 60vw;
    }
}

@media (max-width: 620px){
    .sale {
        display: none;
    }
    .header { 
        height: 250px;
        background: url('/images/bg2.png');
    }
    .header-h1 {
        width: 251px;
        font-weight: 800;
        font-size: 20px;
        line-height: 127.5%;
        letter-spacing: 0.01em;
        top: 30px;
    } 
    .header-h2 {
        width: 100%;
        font-size: 3vw;
        line-height: 7.5vw;
        top: 30px;
        
    } 
    .header-a {
        width: 100%;
        top: 30px;
    }
}




