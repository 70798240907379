.modal {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.699);
    position: fixed;
    top: 0;
    left: 0;
}

.modal__order {
    width: 500px;
    height: 426px;
    background-color: white;
    margin: 0 auto;
    margin-top: 8%;
    position: relative;
}

.modal__form {
    width: 90%;
    margin: 0 auto;
}

.modal__title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #231F1C;
    padding-top: 60px;
    padding-bottom: 10px;
}

.modal__item_wrap {
    width: 350px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.modal__item_p {
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #231F1C;
}

.modal__item_input {
    width: 240px;
    height: 46px;
    border: 1px solid rgba(35, 31, 28, 0.5);
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    padding-left: 10px;
}

.modal__item_input:focus {
    outline: none;
}

.error {
    width: 350px;
    text-align: right;
    margin: 0 auto;
    color: rgba(236, 15, 15, 0.459);
}

.modal__order_button {
    width: 200px;
    height: 50px;
    border: none;
    background-color: #FFC82E;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #231F1C;
    margin-left: 27%;
    margin-top: 25px;
    cursor: pointer;
}

.modal__order_button:hover {
    background: #FFD55F;
}

.modal__order_button:active {
    background: #FFB82E;
}

.modal__order_button:focus {
    outline: none;
}

.modal__cancel_button {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 16px;
    height: 16px;
    background: url('/images/cross-mark.svg');
    cursor: pointer;
}

.modal__success {
    width: 500px;
    height: 378px;
    background-color: white;
    margin: 0 auto;
    margin-top: 8%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.modal__load {
    width: 500px;
    height: 426px;
    margin: 0 auto;
    background-color: #e2e2e286;
    position: relative;
    top: -426px;
}

.modal_loader {
    width: 70%;
    margin: 0 auto;
}

.modal__remove {
    width: 500px;
    height: 300px;
    background-color: white;
    margin: 0 auto;
    margin-top: 10%;
    align-items: center;
}

.success__image {
    width: 150px;
    height: 150px;
    margin-top: 70px;
    background: url('/images/done-icon.svg');
}

.success__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #231F1C;
}

.remove__title {
    width: 100%;
    text-align: center;
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #231F1C;
    padding-top:80px;
}

.modal__remove_buttons {
    width: 100%;
    display: flex;
    justify-content: center;
}

.remove_button {
    width: 22%;
    height: 35px;
    margin: 1%;
    border: none;
    background-color: #FFC82E;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #231F1C;
    cursor: pointer;
    text-align: center;
    padding-top: 12px;
}

.remove_button_cancel {
    width: 22%;
    height: 33px;
    margin: 1%;
    border: 1px solid #FFD55F;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #231f1c8f;
    cursor: pointer;
    text-align: center;
    padding-top: 12px;
}
.remove_button_cancel:hover {
    border-color: #231F1C;
    color: #231F1C;
}

.remove_button_cancel:active {
    border-color: #231F1C;
    color: #231F1C;
}

.remove_button:hover {
    background: #FFD55F;
}

.remove_button:active {
    background: #FFB82E;
}

@media (max-width: 560px) {
    .modal__order {
        width: 100%;
        height: 440px;
        margin: 0;
    }

    .modal__load {
        width: 100%;
        height: 100%;
        top:-440px;
    }

    .modal__title {
        margin-block-start: 0;
        font-size: 18px;
        padding-top: 30px;
    }

    .modal__cancel_button {
        right: 25px;
    }

    .modal__item_wrap {
        flex-direction: column;
    }

    .modal__item_p {
        font-size: 16px;
        margin-top: 0;
        margin-bottom: 5px;
    }

    .modal__item_input {
        width: 90%;
    }

    .modal__success, .modal__remove {
        margin: 0;
        width: 100%;
    }

    .error {
        text-align: start;
    }
}

