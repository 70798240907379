.cart-wrapper {
    width: 80%;
    margin: 0 auto;
}

.cart__item {
    width: 100%;
    height: 180px;
    box-shadow: 0px 0px 30px rgba(48, 44, 42, 0.07);
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.item__image {
    width: 150px;
    height: 150px;
    margin-left: 15px;
}

.cart__image_wrapper {
    height: 150px;
    width: min-content;
    margin: 0 auto;
}
.cart__image {
    height: 100%;
}

.item__title {
    width: 40%;
}

.item__title1{
    font-weight: 600;
    font-size: 22px;
    line-height: 27.94px;
}

.item__title2{
    font-weight: 400;
    font-size: 18px;
    line-height: 21.6px;
    color:  rgba(35, 31, 28, 0.7);
}

.item__count {
    border: 1px solid #231F1C;
    width: 60px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #231F1C;
    margin-right: 2%;
}

.item__count_button {
    border: none;
    background-color: white;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #231F1C;
    cursor: pointer;
}

.item__count_button:focus {
    outline: none;
}

.item__sum {
    border-left: 1px solid #D8D8D8;;
    border-right: 1px solid #D8D8D8;;
    height: 150px;
    width: 16%;
    margin-top: 3px;
    align-items: center;
    text-align: center;
}

.item__sum1 {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;  
    color: rgba(35, 31, 28, 0.7);
    letter-spacing: 0.03em;
    margin-top: 35px;
}

.item__sum2 {
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    color: #231F1C;
    letter-spacing: 0.03em;
}

.item__sum_total {
    height: 150px;
    width: 16%;
    margin-top: 3px;
    align-items: center;
    text-align: center;
}

.item__remove {
    position: absolute;
    margin-top: -140px;
    right: 11%;
    width: 16px;
    height: 16px;
    background: url('/images/cross-mark.svg');
    cursor: pointer;
}

.cart__total {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    border-top: 1px solid #231F1C;
    margin-top: 30px;
    margin-bottom: 110px;
}

.cart__total_p {
    font-weight: 800;
    font-size: 24px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #231F1C;
}

.cart__order_button {
    width: 300px;
    height: 60px;
    background: #FFC82E;
    border: none;
    position: absolute;
    right: 10%;
    margin-top: -100px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #231F1C;
    cursor: pointer;
}

.cart__order_button:hover {
    background: #FFD55F;
}

.cart__order_button:active {
    background: #FFB82E;
}

.cart__order_button:focus {
    outline: none;
}

.item__price_mobile, .cart_hr, .item__total_mobile {
    display: none;
}

.cart_empty {
    width: 100%;
    height: 26vw;
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #231F1C;
    padding-top: 15vw;
}

@media (max-width: 720px) {
    .cart__item {
        height: 417px;
        flex-direction: column;
        align-items: unset;
    }

    .item__image {
        margin: 0 auto;
        margin-top: 20px;
    }

    .item__title {
        width: 90%;
        margin: 0 auto;
    }

    .item__title1, .item__title2 {
        font-size: 18px;
        margin-top: 6px;
        margin-bottom: 8px;
    }

    .item__price_mobile {
        display: block;
        width: 90%;
        font-weight: 600;
        font-size: 18px;
        line-height: 127%;
        color: #231F1C;
        letter-spacing: 0.03em;
        margin-left: 5%;
    }

    .item__sum, .item__sum_total  {
        display: none;
    }

    .item__count {
        width: 90px;
        margin-top: 10px;
        margin-left: 5%;
    }

    .cart_hr {
        display: block;
        width: 90%;
        margin: 0 auto;
        height: 1px;
        background-color: #D8D8D8;
        margin-top: 20px;
    }

    .item__total_mobile {
        display: block;
        width: 90%;
        margin: 0 auto;
        margin-top: 20px;
        text-align: end;
        font-weight: 600;
        font-size: 18px;
        line-height: 127%;
        letter-spacing: 0.03em;
        color: #231F1C;
    }

    .item__remove {
        right: 14%;
        margin-top: 15px;
    }

    .cart__total_p {
        font-size: 20px;
    }

    .cart__order_button {
        width: 80%;
    }
}

