.main{
    height: 250px;   
}

.ad-h2{
    margin: 0 auto;
    width: 90%;
    text-align: center;
    margin-top: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: #231F1C;
}

.adv{
    width: 90%;
    height: 150px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.adv-item{
    width: 270px;
    height: 150px;
}

.adv-img{
    width: 50px;
    height: 50px;
    margin: 0 auto;
}

.adv-h{
    width: max-content;
    margin: 0 auto;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
}

.adv-hr{
    background: #FFC82E;
    width: 198px;
    height: 3px;
    border: none;
}

.adv-p{
    width: 198px;
    margin: 0 auto;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 140%;
    color: #231F1C;
}

@media screen and (max-width: 880px){
    .main{
        height: 700px;
    }
    .adv{
        display: block;
        width: 270px;
        margin: 0 auto;
        margin-top:20px;
    }
}