@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Heavy.ttf") format("truetype"),
    url("../fonts/Lato-Medium.ttf") format("truetype");
}

.about{
    height: 828px;
    margin: 0 auto;
    margin-bottom: 50px;
    position: relative;
    width: 90%;
}

.about-h2{
    margin: 0 auto;
    width: 214px;
    margin-top: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: #231F1C;
}

.img-rect{
    position: absolute;
    top: 147px;
    left:-10%;
    width: 20vw;
}

.img-rect2{
    position: absolute;
    top: 270px;
    right: -2%;
    width: 20vw;
}

.img-img{
    position: absolute;
    top: 85px;
    left: 3%;
    width: 30vw;
}

.img-p2{
    position: absolute;
    width: 20vw;
    height: 220px;
    top: 180px;
    right: 3%;
}

.img-p3{
    position: absolute;
    width: 23vw;
    height: 200px;
    top: 410px;
    right: 22%;
}

.about-p1{
    width: 27.48%;
    height: 1.6vw;
    position: absolute;
    top: 180px;
    left: 41.74%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.about-p2{
    width: 32.91%;
    height: 110px;
    position: absolute;
    top: 475px;
    left: 16%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
}

.about-p3{
    width: 35.18%;
    height: 110px;
    position: absolute;
    top: 668px;
    left: 39.74%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #000000; 
}

@media (min-width: 1280px) {
    .about-p2 {
        bottom: 20%;
        top: auto;
    }
}

@media (max-width: 920px) {
    .about {
        display: none;
    }
}
