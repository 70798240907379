.contacts{
    height: 50px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.contacts-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.contacts-img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
}

.contacts-h4{
    color: #231F1C;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
}


.nav-head{
    height: 90px;
    background: #231F1C;
}

.wrapper{
    width: 90%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-container{
    padding: 0;
    height: 17px;
    width: 70%;
    display: flex;
    justify-content: space-between;
    margin-left: 15%;
    margin-right: 2%;
}

.nav-li{
    display: inline;
    margin-right: 1%;
    margin-left: 1%;
}

.nav-li:nth-child(4) {
    margin-left: 7%;
}

.nav-a{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: white;
    text-decoration: none;
    letter-spacing: 0.05em; 
    text-transform: uppercase;  
}

.nav-a:hover{
    color: #FFC82E;
    text-decoration: underline;
}

.nav-a:active{
    color: #FFB82E;
    text-decoration: underline;
}

.nav-input{
    float: left;
    height: 37px;
    width: 74%;
    background: #231F1C;
    border: none;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: rgba(255, 255, 255, 0.5);
    padding-left: 4%;
    outline: none;
}

.nav-search{
    width: 33%;
    padding: 0px;
    height: 40px;
    border: 1px solid white;
    margin-top: -13px;
    margin-right: 0px;
}

input[type=checkbox], .toggle-menu{
    display:none
}

.search-ico{
    width: 24px;
    height: 24px;
    float: left;
    margin: 8px 0% 8px 4%;
}

.nav-cart {
    width: 37px;
    height: 40px;
    background-image: url('/images/cart.svg');
    text-decoration: none;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    color: black
}

.nav-count {
    background-color: #FFC82E;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-left: 16px;
}

.nav-cart:active {
    color: black;
}

@media (max-width: 880px){
    .contacts{
        display: none;
    }
    .nav-head{
        height: 70px;
    }
    .nav-container {
        margin-right: 0;
    }
    input[type=checkbox]{position:absolute;} 
    input[type=checkbox]:focus{background:none} 
    .toggle-menu {
        display:flex;
        flex-direction: column;
        cursor:pointer;
        width: 24px;
        height: 24px;
    }
    i {
        background-color: white;
        width: 18px;
        height: 1.5px;
        margin-left: 3px;
        margin-top: 5px;
        border-radius: 2px;
        transition:all ease 0.5s;
    }

    .open:nth-child(1) {
        transform: skewY(45deg) translateY(7px) translateX(0.5px);
        height: 2px;
    }

    .open:nth-child(2) {
        opacity: 0;
    }

    .open:nth-child(3) {
        transform: skewY(-45deg) translateY(-6px);
        height: 2px;
    }


    .close:nth-child(1) {
        transform: skewY(0deg) translateY(0px) translateX(0px); 
    }

    .close:nth-child(2) {
        opacity: 1;
    }

    .close:nth-child(3) {
        transform: skewY(-0deg) translateY(-0px);
    }

    input[type=checkbox]:checked ~ .nav-container{
        display:block;
    }
    
    .nav-container{
        display:none;
        position:absolute;
        right:0;
        top:50px;
        width: 100%;
        height: 184px;
        background-color:#393532;
        padding:0;
        z-index:99;
    }
    .nav-li{
        display: block;
        width: 95%;
        margin-left: 0;
        height: 28px;
        background: #231F1C;
        margin-top: 5px;
        padding-top: 12px;
        padding-left: 5%;
    }
    .nav-a {
        text-transform: capitalize;
    }
    .nav-li:first-child{
        margin-top: 60px;
    }
    .nav-input{
        background:#393532;
        width: 80%;
        height: 33px;
    }
    .nav-search{
        position: relative;
        top: -238px;
        width: 85%;
        margin:0 auto;
        margin-top: 55px;
        background:#393532;
        padding: 1px;
        height: 40px;
        margin-bottom: 10px;
    }
    .search-ico{
        width: 10%;
    }

    .nav-logo {
        width: 108px;
    }

    .nav-cart {
        position: absolute;
        right: 15%;
        width: 25px;
        height: 27px;
        background-image: url('/images/cart-litle.svg');
        font-size: 9px;
    }
    
    .nav-count {
        width: 13px;
        height: 13px;
        margin-left: 12px;
    }

}
