.modal__item {
    width: 90%;
    height: 90%;
    margin: 0 auto;
    margin-top: 30px;
    overflow-y:scroll;
    background-color: white;
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}


.item_info{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 300px;
    padding-top: 30px;
    padding-bottom: 30px;
}

.wrap-img{
    width: 30%;
    height: 300px;
    padding-left: 15%;
}

.info-img {
    height: 300px;
    box-shadow: 0px 0px 30px rgba(48, 44, 42, 0.07);
}

.info-item-desc{
    height: 217px;
    width: 60%;
}

.info-title{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #231F1C;
    margin-bottom: 30px; 
}

.info-brand{
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 127.5%;
    letter-spacing: 0.03em;
    color: #231F1C;
    margin-bottom: 20px;
    margin-top: -15px;
}

.info-price{
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #231F1C;
}

.info-description{
    width: 90%;
    margin: 0 auto;
    padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 140%;
    color: #231F1C;
    padding-bottom: 30px;
}

.info-number{
    width: 301px;
    margin-left: 5%;
    padding-top: 40px;
    margin-bottom: 40px;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 127.5%;
    letter-spacing: 0.03em;   
    color: #000000;
    border-bottom: 1px solid #FFC82E;
    text-decoration: none;
}

.info-hr{
    background: #FFC82E;
    border: none;
    width: 301px;
}


.info-button {
    width: 300px;
    height: 60px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase; 
    color: #231F1C;
    border: none;
    background: #FFC82E;
    cursor: pointer;
    margin-top: 20px;
}

.info-button:hover, .top-button:active {
    background: #FFB82E;
}

.info-button:focus {
    outline: none;
}

.info-des {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 127%;
    letter-spacing: 0.03em;
    color: #231F1C;
    margin-left: 5%;
}

@media (min-width: 820px) and (max-width: 920px) {
    .wrap-img{
        padding-left: 0;
    }
    .item_info {
        width: 90%;
        margin: 0 auto;
    }
    .item_info {
        justify-content: space-between;
    }

}

@media (max-width: 820px) {
    .item_info {
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 370px;
    }

    .wrap-img {
        width: max-content;   
        height: 220px;
        padding: 0;
        
    }

    .info-img {
        height: 100%;
        width: auto;
        box-shadow: none;
    }

    .info-item-desc {
        width: 90%;
    }

    .info-title {
        font-size: 18px;
        margin-bottom: 20px;
    }

    .info-price {
        margin-top: -10px;
        font-size: 16px;
    }

    .info-brand {
        font-size: 14px;
    }

    .info-button {
        width: 100%;
    }

    .info-des {
        font-size: 18px;
        margin-top: 20px;
    }

    .info-description {
        font-size: 16px;
    }

}