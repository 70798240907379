.menu{
    width: 25%;
}

.dropbtn {
    background-color: #231F1C;
    color: white;
    padding: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    border: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    text-align: left;
}
  
.dropdown{
   margin-bottom: 1px;
}

.dropdown-content {
    display: none;
    background: #393532;
}

  
.menu-item {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    padding: 12px 16px;
    border: none;
    outline: none;
    background: #393532;
    width: 100%;
    text-align: left;
    cursor: pointer;
}
  
.menu-item:hover {
    background: #FFC82E;
}

.block{
    display: block;
}

.dropbtn:hover{
    color: #eeeeeec0;
}
.dropbtn2 {
    background: #393532;
    color: white;
    padding: 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    border: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    text-align: left;
}
  
.dropdown2 {
   margin-bottom: 1px;
   position: relative;
}

.dropdown-content2 {
    display: none;
    background: #4D4946;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

  
.dropdown-content2 .menu-item {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    background: #4D4946;
}
  
.dropdown-content2 .menu-item:hover {
    color: #FFC82E;
    background: #4D4946;
}

.dropdown-content2 .menu-item:active {
    color: #FFB82E;
    background: #4D4946;
}

.dropdown2:hover .dropdown-content2 {
    display: block;
    position: absolute;
    left: 100%;
    width: 100%;
    top: 0px;
    z-index: 1;
}
  
.dropbtn2:hover{
    background: #FFC82E;
}

.input-menu, .toggle-menu2{
    display:none;
}

@media screen and (max-width: 920px){
    .menu{
        width: 40%;
    }
}

@media screen and (max-width: 610px){
    .menu{
        width: 100%;
        margin-top: -30px;
    }
    .input-menu{position:absolute;} 
    .input-menu:focus{background:none} 
    .toggle-menu2{
        display:inline-block;
        cursor:pointer;
        width: 95%;
        height: 30px;
        background: #393532;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: white;
        padding-top: 10px;
        padding-left: 5%;
    }
    .toggle-menu2 > img {
        position: absolute;
        right: 5%;
    }
    .input-menu:checked ~ .dropdown0{
        display:block;
    }
    .dropdown0{
        display:none;
        position:absolute;
        width: 100%;
        top: 110px;
        height: auto;
        background-color:white;
        padding:0;
        z-index:90;
    }  
    .dropdown2, .dropbtn, .dropbtn2, .menu-item{
        font-size: 14px;
        line-height: 17px;
        font-weight: normal;
    }
    .dropdown2:hover .dropdown-content2 {
        display: none;
    }
    .menu-item{
        width: 100%;
    }
}