@font-face {
    font-family: "Lato";
    src: url("../fonts/Lato-Heavy.ttf") format("truetype"),
    url("../fonts/Lato-Medium.ttf") format("truetype");
}

.top{
    height: 1222px;
    background-color: #F9F9F9;
}

.wrap-top-list{
    width: 90%;
    margin: 0 auto;
}

.list-top{
    width: 100%;
    margin-top: 30px;
}

.top-row{
    width: 100%;
    height: 470px;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
}

.top-item {
    box-shadow: 0px 0px 30px rgba(48, 44, 42, 0.07);
    width: 370px;
    position: relative;
}

.top-a {
    display: block;
    width: 200px;
    height: 40px;
    background-color: #F9F9F9;
    outline: none;
    border: 2px solid #231F1C;
    margin: 0 auto;
    text-decoration: none;
    color: #231F1C;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    text-align: center;
    justify-content: center;
    padding-top: 20px;  
    margin-top: 20px;
}


.top-h2{
    margin: 0 auto;
    width: 90%;
    text-align: center;
    padding-top: 50px;
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 140%;
    color: #231F1C;
}



.top-price{
    height: 30px;
    margin-top: 10px;
    margin-left: 5%;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #231F1C;
}

.list-top .top-img{
    height: 100%;
    cursor: pointer;
}

.list-top .top-title{
    width: 90%;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    color: #231F1C;
    margin: 0 auto;
    margin-top: 18px;
}

.top-title:hover {
    color: #FFB82E;
    cursor: pointer;
}


.top-img-wrap{
    height: 280px;
    width: min-content;
    margin: 0 auto;
    margin-top: 5px;
}

.top-button {
    width: 90%;
    margin-left: 5%;
    height: 60px;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase; 
    color: #231F1C;
    border: none;
    background: #FFC82E;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
}

.top-button:hover, .top-button:active {
    background: #FFB82E;
}

.top-button:focus {
    outline: none;
}


@media (min-width: 900px) and (max-width: 1300px) {
    .top{
        height: 1052px;
    }
    
    .top-row{
        height: 400px;
        justify-content: center;
    }
    
    .top-item {
        width: 270px;
        margin: 1%;
    }

    .top-img-wrap{
        height: 200px;
    }

    .list-top .top-title {
        margin-top: 5px;
    }

    .top-button {
        height: 50px;
    }
}

@media (min-width: 420px) and (max-width: 900px) {
    .top{
        height: 3230px;
    }
    
    .top-row{
        height: 1500px;
        flex-direction: column;
        align-items: center;
        margin-bottom: -10px;
    }
    
    .top-item {
        width: 370px;
        height: 470px;
        margin: 1%;
    }
}

@media (max-width: 420px) {
    .top{
        height: 3230px;
    }
    .wrap-top-list {
        width: 100%;
    }

    .top-row{
        width: 100%;
        height: 1500px;
        flex-direction: column;
        align-items: center;
        margin-bottom: -10px;
    }
    
    .top-item {
        width: 100%;
        height: 470px;
        margin: 1%;
    }
}

