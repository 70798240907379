.about__flex-container {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 30px;

}

.about__flex-container__item {
    width: 50%;
}

.about__image1 {
    width: 95%;
    z-index: 1;
    position: relative;
}

.about__image2 {
    margin-left: 7%;
    margin-top: 40px;
    width: 93%;
    z-index: 3;
    position: relative;
}

.about__text1 {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    margin-top: 90px;
    margin-left: 5%;
}

.about__text2 {
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: #000000;
    margin-top: 10%;
    padding-right: 5%;
    margin-left: 2%;
}

.about__reg1 {
    position: absolute;
    left: 0;
    top: 250px;
    width: 25%;
}

.about__reg2 {
    position: absolute;
    right: 0;
    margin-top: 90px;
    width: 25%;
}

.sert{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    height: fit-content;
}

.sert-img{
    width: 17%;
}

@media (max-width: 640px){
    .about__flex-container {
        display: block;
    }

    .about__text1, .about__reg2, .about__image2 {
        display: none;
    }

    .about__flex-container__item {
        width: 100%;
    }

    .about__image1 {
        width: 100%;
    }

    .about__reg1 {
        top: 240px;
        width: 45%;
    }

    .about__text2 {
        margin-top: 12%;
    }

    .sert {
        display: block;
        width: 60%;
    }
    
    .sert-img {
        width: 100%;
    }
}
