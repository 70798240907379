.list{
    width: 74%;
}

.search-list .list {
    width: 100%;
}

.list-item {
    width: 31%;
    height: 27vw;
    float: left;
    margin: 1%;
    display: block;
    box-shadow: 0px 0px 30px rgba(48, 44, 42, 0.07);
    text-decoration: none;
    position: relative;
} 

.list-img {
    height: 100%;
}

.list-img-wrap{
    height: 12vw;
    width: min-content;
    margin: 0 auto;
    margin-top: 5px;
}

.list-img-wrap:hover {
    cursor: pointer;
}

.list-title-wrap{
    width: 90%;
    margin: 0 auto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2vw;
    line-height: 140%;
    color: #231F1C;
}

.list-title:hover {
    color: #FFB82E;
    cursor: pointer;
}

.list-price{
    margin-top: 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2vw;
    line-height: 19px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #231F1C;
    position: absolute;
    bottom: 5.5vw;
}

.list-title {
    width: 90%;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2vw;
    line-height: 140%;
    color: #231F1C;
    margin: 0;
    margin-top: 10px;
}

.loader{
    width: 100%;
    margin-top: 30px;
}

.wrapper-loader{
    width: 10%;
    margin: 0 auto;
}

.tool-button {
    width: 90%;
    height: 4vw;
    font-weight: 600;
    font-size: 1.1vw;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase; 
    color: #231F1C;
    border: none;
    background: #FFC82E;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
}

.tool-button:hover, .top-button:active {
    background: #FFB82E;
}

.tool-button:focus {
    outline: none;
}


@media (max-width: 880px){
    .list-item {
        float: none;
        width: 270px;
        height: 345px;
        margin: 0 auto;
    }
    
    .list-img-wrap{
        height: 180px;
        width: min-content;
        margin: 0 auto;
        margin-top: 5px;
    }
    
    .list-title{
        font-size: 14px;
        overflow-wrap: break-word;
        width: 100%;
    }
    
    .list-price{
        font-size: 16px;
        position:static;
    }

    .tool-button {
        height: 50px;
        font-size: 14px;
    }
}

@media (max-width: 615px) {
    .list {
        width: 100%;
    }
}
