.footer{
    height: 155px;
    background-color: #231F1C;
    padding-top: 70px;
}

.wrapper-footer{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fot-logo{
    width: 210px;
    height: 79px;
}

.fot-nav{
    width: 73px;
    height: 81px;
}

.fot-adr{
    width: 176px;
    height: 50px;
}

.fot-phone{
    width: 163px;
    height: 79px;
}

.fot-h2{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-top: 7px;
}

.fot-container{
    padding: 0;
    list-style-type: none; 
    height: 81px;
    align-content: space-between;
    margin-top: 0;
}

.fot-li{
    margin-bottom: 10px;
}

.fot-a{
    text-decoration: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;  
    color: #FFFFFF;
}

.fot-work{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}

.fot-h4{
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    margin-left: 7px;
    text-decoration: none;
}

@media screen and (max-width: 920px){
    .footer{
        height: 380px;
    }
    .wrapper-footer{
        display: block;
    }
    .fot-nav{
        width: 90%;
        margin: 0 auto;
        margin-top: -10px;
    }
    .fot-li{
        width: 100%;
        text-align: center;
        text-decoration: underline;
        font-size: 14px;
    }
    .fot-adr, .fot-phone{
        margin: 0 auto;
        margin-top: 30px;
        width: 158px;
    }
    .fot-phone{
        margin-top: 0px;
    }
    .fot-work{
        width: 162px;
    }
    .fot-logo{
        margin: 0 auto;
        margin-top: -30px;
    }
    .fot-logo > img {
        display: block;
        margin: 0 auto;
    }
    .fot-h4{
        font-size: 14px;
        width: 140px;
    }
    .fot-h2{
        position: relative;
        font-size: 12px;
        top: 310px;
        width: 100%;
        text-align: center;
        margin: 0 auto;
    }
}