.map{
    height: 400px !important;
    width: 70% !important;
    margin: 0 auto;
    background: #F9F9F9;
    margin-bottom: 50px;
}

.wrapper-catalog{
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 30px;
}

@media (max-width: 920px) {
    .map {
        margin-bottom: 0;
        width: 99% !important;        
    }
}

@media screen and (max-width: 610px){
    .wrapper-catalog{
        width: 100%;
        display: block;
    }
}
